.main {
  display: flex;
  padding: 32px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.main h2 {
  font-size: 16px;
  font-weight: 300;
}
.main h2 a {
  gap: 4px;
  display: flex;
  text-decoration: none;
  justify-content: right;
}

.mainDescription {
  margin: 0 auto;
  max-width: 80%;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
}

.formContainer {
  padding: 40px 20px;
}
.formContainer a {
  color: var(--client-primary-color);
}

.header {
  margin-bottom: 20px;
}
.header img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 1101px) {
  .header,
  .paper {
    width: 1100px;
  }
}
@media screen and (max-width: 1100px) {
  .header,
  .paper {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .main h2 {
    font-size: 14px;
  }
  .main h2 svg {
    font-size: 20px;
  }
  .mainDescription {
    font-size: 14px;
    max-width: 100%;
  }
}
