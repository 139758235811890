.modalTitle {
  text-align: center;
  color: white !important;
  font-size: 18px !important;
  padding: 10px 24px !important;
  margin-bottom: 20px !important;
  background: linear-gradient(to bottom, #222222, black) !important;
}

.modalHeader {
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  text-align: center;
  background: #f2f2f2;
  color: var(--client-accent-color);
}

.modalMessage {
  text-align: center;
  max-width: 260px !important;
  margin-bottom: 20px !important;
}

.note {
  cursor: pointer;
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}
.note strong {
  color: var(--client-primary-color);
}
.note strong:hover {
  text-decoration: underline;
}

.submitButton {
  width: 260px !important;
}
