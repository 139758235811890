.header {
  margin-bottom: 20px;
}

.header img {
  width: 100%;
  max-width: 188px;
  height: auto;
}

.header h1 {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: var(--app-primary-color);
}

.header .intakeButton {
  margin-right: 14px;
}

.header img {
  cursor: pointer;
}

@media screen and (min-width: 1101px) {
  .header {
    width: 1100px;
  }
  .header .gridItem {
    width: 30%;
  }
}
@media screen and (max-width: 1100px) {
  .header {
    width: 100%;
  }
  .header .gridItem {
    width: 30%;
  }
}
@media screen and (max-width: 768px) {
  .header h1,
  .header .gridItem:nth-child(2) {
    display: none;
  }
  .header .gridItem {
    width: 50%;
  }
}
@media screen and (max-width: 480px) {
  .header .intakeButton {
    margin-right: 7px;
    font-size: 10px;
  }
}
