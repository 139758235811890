@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

:root {
  --font-roboto: 'Roboto', sans-serif;

  --app-primary-color: #254f38;
  --app-bg-color: #edf4f0;

  --client-accent-color: #02acb0;
  --client-primary-color: #a33896;
  --client-secondary-color: #672668;
  --client-secondary-hover-color: #501d52;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-size: 16px !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000000;
  transition: background-color 5000s ease-in-out 0s;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
  min-width: 375px;
  max-width: 100vw;
  overflow-x: hidden;
  background: linear-gradient(to bottom, #e5e5e5, white);
}

a {
  color: inherit;
  text-decoration: none;
}

footer {
  margin-top: 20px;
}
footer p {
  color: gray !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  text-align: center !important;
}
