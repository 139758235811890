.main {
  display: flex;
  padding: 32px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.main p {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: rgb(211, 47, 47);
}

.paper {
  padding: 20px;
}

.section {
  display: flex;
  padding: 24px 0;
  flex-direction: row;
  justify-content: space-between;
}
.sectionColumn {
  display: flex;
  padding: 32px 0 0 0;
  flex-direction: column;
  justify-content: space-between;
}
.section h2,
.sectionColumn h2 {
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  margin-bottom: 20px;
  color: var(--app-primary-color);
  background: var(--app-bg-color);
  text-align: center;
  padding: 10px;
}

.requiredAsterisk {
  color: rgb(211, 47, 47);
  white-space: nowrap;
}

.pagination {
  padding: 10px;
  display: flex;
  margin-top: 20px;
  border-radius: 4px;
  justify-content: center;
  background: rgba(0, 0, 0, 0.04);
}

.tab[aria-selected='true'] {
  font-weight: 700 !important;
}
.tabsBg {
  border-radius: 4px;
  background: var(--app-bg-color);
}
.tableHead {
  background: #f2f2f2;
}

.deleteBtn {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
}

@media screen and (min-width: 1101px) {
  .paper {
    width: 1100px;
  }
}
@media screen and (max-width: 1100px) {
  .paper {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .section {
    gap: 64px;
    flex-direction: column;
  }
}
