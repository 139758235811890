.readOnly input {
  cursor: default !important;
}

.modalTitle {
  display: flex !important;
  font-size: 18px !important;
  padding: 10px 24px !important;
  margin-bottom: 20px !important;
  align-items: center !important;
  justify-content: space-between !important;
  color: var(--app-primary-color) !important;
  background-color: var(--app-bg-color) !important;
}

.messageDetails {
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  text-align: center;
  color: var(--app-primary-color);
  background: var(--app-bg-color);
}

.note {
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
}

@media screen and (max-width: 480px) {
  .cancelButton {
    display: none !important;
  }
}
