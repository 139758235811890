.main {
  display: flex;
  padding: 32px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.main p {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: rgb(211, 47, 47);
}

.panel {
  padding: 24px;
  overflow: hidden;
  border: 1px solid #cccccc;
  border-top-color: transparent;
}

.paper {
  padding: 20px;
}

.tab[aria-selected='true'] {
  font-weight: 700 !important;
}
.tabsBg {
  border-radius: 4px;
  background: var(--app-bg-color);
}

.filterContainer {
  margin-bottom: 20px;
}

@media screen and (min-width: 1101px) {
  .paper {
    width: 1100px;
  }
}
@media screen and (max-width: 1100px) {
  .paper {
    width: 100%;
  }
}
