.modalTitle {
  display: flex !important;
  font-size: 18px !important;
  padding: 10px 24px !important;
  margin-bottom: 20px !important;
  align-items: center !important;
  justify-content: space-between !important;
  color: var(--app-primary-color) !important;
  background-color: var(--app-bg-color) !important;
}

.description {
  padding: 10px;
  white-space: pre-wrap;
  background-color: #f0f0f0 !important;
  border: 1px solid #cccccc !important;
  color: var(--app-primary-color) !important;
}
